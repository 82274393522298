import React, { useEffect, useState } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import "./timeline.css";
//import vote from "../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Scrollimage from "./scroll-image";
import ScrollImage2 from "../../template/scroll-image";
import AOS from "aos";
import "aos/dist/aos.css";

function Timeline() {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 600);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  useEffect(() => {
    AOS.init({ duration: 200 });
  }, []);

  return (
    <ParallaxProvider>
      <div style={{ backgroundColor: "#04051d" }}>
        <Scrollimage />
        <br />
        <div style={{ marginTop: "400px" }}>
          {/** PC, Laptop, Tab view */}
          <div className="timeline-desktop">
            <div style={{ marginLeft: "70px", marginRight: "70px" }}>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
              </div>

              {/* 1940 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{ borderLeft: "10px solid #5c5c5c" }}
                      >
                        {" "}
                        <br />
                        1940
                      </h2>
                    </Parallax>
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">1940</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "90px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Father and son, Peter Hapangama and David Hapangama
                        start rubber trading operations.
                      </p>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
              </div>

              {/* 1983 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{
                          textAlign: "end",
                          borderRight: "10px solid #5c5c5c",
                        }}
                      >
                        <br />
                        1983&nbsp;
                      </h2>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div
                    style={{ marginLeft: "40px", marginRight: "40px" }}
                  ></div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <h2 className="timeline-h2"></h2>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">1983</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "90px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        David's son, Lalith Hapangama, joins the family business
                        - bringing in value added manufacturing concepts
                      </p>
                    </Parallax>
                  </div>
                </div>
              </div>

              {/* 1984 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{ borderLeft: "10px solid #5c5c5c" }}
                      >
                        <br />
                        &nbsp;1984
                      </h2>
                    </Parallax>
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">1984</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "90px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        The first Lalan Printing and Packaging factory is
                        commissioned, marking the first diversification from the
                        core rubber trading business.
                      </p>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <h2 className="timeline-h2"></h2>
                  </div>
                </div>
              </div>

              {/* 1987 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{
                          textAlign: "end",
                          borderRight: "10px solid #5c5c5c",
                        }}
                      >
                        <br />
                        1987&nbsp;
                      </h2>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">1987</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "90px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Lalan Rubbers is established, and begins rubber glove
                        manufacturing operations. This eventually overtakes
                        rubber trading as the core business of the growing
                        group.
                      </p>
                    </Parallax>
                  </div>
                </div>
              </div>

              {/* 1988 - 1998 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{ borderLeft: "10px solid #5c5c5c" }}
                      >
                        <br />
                        &nbsp;1988 - 1998
                      </h2>
                    </Parallax>
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">1988 - 1998</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "70px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Lalan Rubbers expands from one production facility to
                        six production facilities.
                      </p>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <h2 className="timeline-h2"></h2>
                  </div>
                </div>
              </div>

              {/* 1998 - 2000 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{
                          textAlign: "end",
                          borderRight: "10px solid #5c5c5c",
                        }}
                      >
                        <br />
                        1998 - 2000&nbsp;
                      </h2>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">1998 - 2000</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "70px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Further expansion sees Lalan Rubbers and Lalan Printing
                        and Packaging grow.
                      </p>
                    </Parallax>
                  </div>
                </div>
              </div>

              {/* 2003 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{ borderLeft: "10px solid #5c5c5c" }}
                      >
                        <br />
                        &nbsp;2003
                      </h2>
                    </Parallax>
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">2003</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "90px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Lalan diversifies into plantation sector; currently
                        holds over 17,000 acres of rubber, tea and coconut
                        plantations.
                      </p>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <h2 className="timeline-h2"></h2>
                  </div>
                </div>
              </div>

              {/* 2004 - 2009 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{
                          textAlign: "end",
                          borderRight: "10px solid #5c5c5c",
                        }}
                      >
                        <br />
                        2004 - 2009&nbsp;
                      </h2>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div
                    style={{ marginLeft: "40px", marginRight: "40px" }}
                  ></div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <h2 className="timeline-h2"></h2>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">2004 - 2009</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "70px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Lalan builds on its international presence through the
                        acquisition of several UK-based companies. Further
                        diversification follows into the engineering and leisure
                        sectors.
                      </p>
                    </Parallax>
                  </div>
                </div>
              </div>

              {/* 2009 - 2014 */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{ borderLeft: "10px solid #5c5c5c" }}
                      >
                        <br />
                        &nbsp;2009 - 2014
                      </h2>
                    </Parallax>
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <p className="timeline-h1">2009 - 2014</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "70px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        Lalan Rubbers sets out on an expansion drive, growing to
                        nine production facilities and expanding its product
                        portfolio. Lalan ventures into the renewable energy
                        sector.
                      </p>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <h2 className="timeline-h2"></h2>
                  </div>
                </div>
              </div>

              {/* 2014 - Now */}
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <br />
                    <Parallax speed={-20}>
                      <h2
                        className="timeline-h2"
                        data-aos="fade-down"
                        style={{
                          textAlign: "end",
                          borderRight: "10px solid #5c5c5c",
                          marginTop: "140px",
                        }}
                      >
                        <br />
                        2014 - Now&nbsp;
                      </h2>
                    </Parallax>
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
              </div>
              <div className="container-timeline">
                <div className="column" id="columnA">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {" "}
                  </div>
                </div>
                <div className="column" id="columnB">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            <div className="vertical-line2"></div>
                            <div className="vertical-line1"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="column" id="columnC">
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <Parallax speed={30}>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <p className="timeline-h1">2014 - Now</p>
                      <div style={{ marginLeft: "-20px", marginTop: "-310px" }}>
                        <div
                          style={{
                            widows: "100%",
                            height: "300px",
                            borderLeft: "10px solid #030419",
                            borderRight: "10px solid #030419",
                            borderBottom: "10px solid #030419",
                            marginRight: "70px",
                          }}
                        ></div>
                      </div>{" "}
                      <br />
                      <p className="timeline-p">
                        The fourth generation of the family joins the business.
                        Lalan's international operations further expanded with
                        the creation of Lalan Malaysia, Lalan Brazil, Lalan
                        Turkey, and Lalan Middle East.
                      </p>
                    </Parallax>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/** Mobile view */}
          <div
            className="timeline-mobile"
            style={{
              marginBottom: "270px",
              marginRight: "70px",
              marginLeft: "70px",
            }}
          >
            <div>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                1940
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">1940</p>
                <p className="timeline-p">
                  Father and son, Peter Hapangama and David Hapangama start
                  rubber trading operations.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                1983
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">1983</p>
                <p className="timeline-p">
                  David's son, Lalith Hapangama, joins the family business -
                  bringing in value added manufacturing concepts
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                1983
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">1984</p>
                <p className="timeline-p">
                  The first Lalan Printing and Packaging factory is
                  commissioned, marking the first diversification from the core
                  rubber trading business.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                1987
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">1987</p>
                <p className="timeline-p">
                  Lalan Rubbers is established, and begins rubber glove
                  manufacturing operations. This eventually overtakes rubber
                  trading as the core business of the growing group.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                1988 - 1998
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">1988 - 1998</p>
                <p className="timeline-p">
                  Lalan Rubbers expands from one production facility to six
                  production facilities.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                1998 - 2000
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">1998 - 2000</p>
                <p className="timeline-p">
                  Further expansion sees Lalan Rubbers and Lalan Printing and
                  Packaging grow.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                2003
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">2003</p>
                <p className="timeline-p">
                  Lalan diversifies into plantation sector; currently holds over
                  17,000 acres of rubber, tea and coconut plantations.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                2004 - 2009
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">2004 - 2009</p>
                <p className="timeline-p">
                  Lalan builds on its international presence through the
                  acquisition of several UK-based companies. Further
                  diversification follows into the engineering and leisure
                  sectors.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p className="timeline-h2" style={{ marginBottom: "100px" }}>
                2009 - 2014
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">2009 - 2014</p>
                <p className="timeline-p">
                  Lalan Rubbers sets out on an expansion drive, growing to nine
                  production facilities and expanding its product portfolio.
                  Lalan ventures into the renewable energy sector.
                </p>
              </Parallax>
            </div>

            <div style={{ marginTop: "500px" }}>
              <p
                className="timeline-h2"
                data-aos="fade-down"
                style={{ marginBottom: "100px" }}
              >
                2014 - Now
              </p>
              <Parallax speed={20}>
                <p className="timeline-h1">2014 - Now</p>
                <p className="timeline-p">
                  The fourth generation of the family joins the business.
                  Lalan's international operations further expanded with the
                  creation of Lalan Malaysia, Lalan Brazil, Lalan Turkey, and
                  Lalan Middle East.
                </p>
              </Parallax>
            </div>
          </div>
        </div>

        <ScrollImage2 />

        {/* BestWeb  */}
        <div
          style={{
            marginLeft: isMobile ? "0px" : "40px",
            marginTop: isMobile ? "-300px" : "-280px",
            marginBottom: "50px",
            zIndex:"10"
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* BestWeb - start */}
            {/* <div>
              <a
                href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                target="_blank"
              >
                <img className="logopic" src={vote} alt="logo" />
              </a>
            </div> */}
            {/* BestWeb - end */}

            {/* TopWeb - start */}
            <div>
              <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
                <img
                  style={{ marginLeft: "30px" }}
                  className="logopic-2"
                  src={TopWeb}
                  alt="logo"
                />
              </a>
            </div>
            {/* TopWeb - end */}
          </div>
        </div>

        <br />
      </div>
    </ParallaxProvider>
  );
}

export default Timeline;
