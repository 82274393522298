import React, { useState, useRef, useEffect } from "react";
import NavBar from "../../components/navbar";
import "../../components/homevideo.css";
import TimelineMaincontent from "../../template/Timeline/timeline";
import Arrow from "../../template/ScrollToTopButton";
import Footer3CS from "../../components/footerbar";
import { Helmet } from "react-helmet";

const Leisure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setScrollY = useState(0)[1];
  const mainRef = useRef(null);

  const handleClick = () => {
    setScrollY(100);
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Timeline | Lalan Group Sri Lanka</title>
        <meta name="title" content="Timeline | Lalan Group Sri Lanka" />
        <meta
          name="description"
          content="Take a look at the long journey we had since 1940 and the achievements we have made up to the present day. "
        />
        <meta name="keywords" content="Lalan Group Sri Lanka" />
        <link
          rel="canonical"
          href="https://www.lalangroup.com/aboutus/timeline/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Timeline - Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
        <meta
          property="og:url"
          content="https://www.lalangroup.com/aboutus/timeline/"
        />
        <meta
          property="og:site_name"
          content="Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
      </Helmet>

      <NavBar />
      <div style={{ position: "relative" }}>
        <div className="fullpage-video-set">
          <img
            className="background-video-set"
            src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/top-banner5.jpg"
            alt="background-video"
          />
          {/* Media query for screens up to 1000px */}
          <style>
            {`@media (max-width: 1100px) {
            .background-video-set {
             content: url(${"https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/top-banner5-II.jpg"});
            }
          }`}
          </style>
          <div className="video-overlay">
            <p>
              <div className="optimanormal">
                Timeline{" "}
                <h1 style={{ color: "transparent", marginTop: "-150px" }}>
                  Timeline
                </h1>
              </div>
            </p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="mouse-color-timeline">
            <img
              onClick={handleClick}
              src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/uploads/images/gif-mouse.gif"
              alt="button"
              className="mouse-button"
              style={{ height: "90px", zIndex: "10" }}
            />
          </div>
        </div>
      </div>

      <div ref={mainRef}>
        {/* Main content */}
        <div>
          <TimelineMaincontent />
        </div>
      </div>

      <div style={{ backgroundColor: "#04051d" }}>
        <br />
        <Footer3CS />
      </div>
      <Arrow />
    </div>
  );
};

export default Leisure;
